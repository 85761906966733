.container{

}

.container div{
  display: block;
}

div.input{
  margin: 10px;
}
